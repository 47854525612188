import React, { useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
// import AuthContext from '../context/auth'

import firebase from 'gatsby-plugin-firebase'
import useAuthState from '../functions/useAuthState'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe} from '@stripe/stripe-js';

import Helmet from "react-helmet"

import '../assets/scss/main.scss'

const backdropClasses = " backdrop"

const Layout = ({ children, bodyClass, pageSlug }) => {

  const [backdropActive, setBackdropActive] = useState(false)

  const toggleBackdrop = (e) => {
    e.preventDefault()
    const active = (backdropActive) ? false : true;
    setBackdropActive(active)
  }

  const [user, loading, error] = useAuthState(firebase)

  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

  return (
    <Elements stripe={stripePromise}>
      <div
        id={"GatsbyBody"}
        className={
          bodyClass +
          " " +
          (backdropActive ? backdropClasses : "")
        }
      >
        <Header toggleBackdrop={toggleBackdrop} user={user} />
		
        <main id="site-content" role="main">
          {children}
        </main>

        <Footer pageSlug={pageSlug ? pageSlug : '/'} />
      </div>
    </Elements>
  )
}

export default Layout
